import request from '@/util/axios-mu-hu'

// 获取示范点牧户 列表
export function getMuHuList() {
  return request({
    url: '/salesapp/bigscreen/user/list',
    method: 'get',
  })
}

// 获取所有开关
export function getKaiGuanList() {
  return request({
    url: "/salesapp/bigscreen/hardwareMng/list",
    method: 'get'
  })
}

// 获取 【饲喂】 开关
export function getSiWeiKaiGuanList(data) {
  return request({
    url: `/salesapp/bigscreen/hardwareMng/list/?dictValue=siwei&userId=${data.id}`,
    method: 'get'
  })
}

// 获取 【消杀】 开关
export function getXiaoShaKaiGuanList(data) {
  return request({
    url: `/salesapp/bigscreen/hardwareMng/list/?dictValue=xiaosha&userId=${data.id}`,
    method: 'get'
  })
}

// 获取 【清粪】开关
export function getQingFenKaiGuanList(data) {
  return request({
    url: `/salesapp/bigscreen/hardwareMng/list/?dictValue=qingfen&userId=${data.id}`,
    method: 'get'
  })
}

// 获取【饮水】开关
export function getYinShuiKaiGuanList(data) {
  return request({
    url: `/salesapp/bigscreen/hardwareMng/list/?dictValue=shui&userId=${data.id}`,
    method: 'get'
  })
}

// 点击开关 (发送开关状态)
export function postKaiGuanStatus(params) {
  return request({
    url: "/switchHardware",
    method: 'post',
    params
  })
}

// 点击开关 (获取验证码)
export function getCode(data) {
  return request({
    url: `/salesapp/bigscreen/switchHardware/getCaptcha?id=${data.id}`,
    method: 'get',
  })
}

// 获取 养殖档案
export function getYangZhiDangAn(data) {
  return request({
    url: `/salesapp/bigscreen/breedingRecods/list/?userId=${data.id}`,
    method: 'get'
  })
}

// 获取 品种统计
export function getPinZhongTongJi() {
  return request({
    url: '/salesapp/bigscreen/animalTypeStatis',
    method: 'get'
  })
}

// 获取 牲畜统计
export function getShengChuTongJi() {
  return request({
    url: '/salesapp/bigscreen/animalStatis',
    method: 'get'
  })
}

// export function logout () {
//   return request({
//     url: 'accessUser/logout',
//     method: 'post'
//   })
// }

