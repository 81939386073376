<template>
  <div>
    <v-chart :options="options" autoresize/>
  </div>
</template>

<script>
export default {
  name: "LineChart",
  components: {},
  props: {
    value: Object,
    ispreview: Boolean
  },
  data() {
    return {
      options: {
        grid: {},
        color: ['#1E90FF'],
        title: {
          text: "",
          show: false,
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}%"
        },
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: ["1", "2", "3", "4", "5", "6", "7"],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          },
          inverse: false,
          scale: false,
          splitLine: {
            lineStyle: {
              color:"#1E90FF",
              width:1
            }, 
            show: false

          }

        },
        series: [
          {
            areaStyle: {
              opacity: 0.05,
            },
            data: [100, 130, 120, 140, 150, 160, 150],
            lineStyle: {
              width: 4
            },


            type: "line",
            showSymbol: true,
            smooth: true,
            symbol: "circle",
            symbolSize: 10,

          }
        ]
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsCollapse: {}, // 图标属性
      optionsSetup: {
        customColor: [
         { color: "#1E90FF" }
        ],
        fontSize:12,
        fontSizeX:14,
        fontSizeY:14,
        fontWeight:"normal",
        hideX:true,
        isNoTitle:true,
        isShow:false,
        isShowSplitLineX:false,
        isShowSplitLineY:false,
        isShowY:true,
        lineWidthX:1,
        marginBottom:0,
        marginLeft:10,
        marginRight:40,
        marginTop:0,
        markPoint:true,
        nameColorX:"#fff",
        nameColorY:"#fff",
        nameFontSizeX:14,
        nameFontSizeY:14,
        nameX:"",
        pointSize:10,
        reversalX:false,
        reversalY:false,
        scale:false,
        smoothCurve:true,
        splitLineColorX:"#fff",
        splitLineColorY:"#fff",
        splitLineWidthX:1,
        splitLineWidthY:1,
        splitNumberY:"",
        subText:"",
        subTextColor:"#fff",
        subTextFontSize:20,
        subTextFontStyle:"normal",
        subTextFontWeight:"normal",
        symbol:"circle",
        textAlign:"center",
        textAngleX:0,
        textAngleY:0,
        textColor:"#FFD700",
        textFontSize:20,
        textFontStyle:"normal",
        textFontWeight:"normal",
        textInterval:"",
        textNameY:"",
        tipsColor:"#00FEFF",
        tipsFontSize:16,
        titleText:"",


      }
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  created() {
    
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.test { color: #fff; }
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // border: 1px solid #fff;
}
</style>
