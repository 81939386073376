<template>
  <div class="text">
    <div class="table_box">
      <div class="table_header">
        <span class="text_1 header_item color_255_280_0">设备名称</span>
        <span class="text_1 header_item color_255_280_0">设备状态</span>
        <!-- <span class="header_item">水温</span> -->
      </div>

      <div class="table_body_box">
        <div
          class="table_body"
          v-for="(item, index) in kaiGuanList"
          :key="index"
        >
          <span class="text_1 body_item color_255_280_0">{{ item.title }}</span>
          <span class="text_1 body_item color_255_280_0">{{ formatStatus(item.swithType) }}</span>
          <!-- <span class="body_item">xxx</span> -->
          <span
            class="body_item kai_guan_box"
            @click="handlerClickKaiGuan(index)"
          >
            <img v-if="!item.swithType" :src="kai_guan_img_off" alt="" />
            <img v-else :src="kai_guan_img" alt="" />
          </span>
        </div>
      </div>
    </div>

    <el-dialog
      title="输入验证码"
      :visible.sync="inputCodeDialog"
      width="30%"
      append-to-body
      class="input_dialog"
    >
      <div class="input_box">
        验证码 &nbsp;<input type="text" v-model="code" />
      </div>
      <span slot="footer">
        <el-button @click="inputCodeDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmDialog()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getYinShuiKaiGuanList,
  postKaiGuanStatus,
  getCode,
} from "@/config/mu-hu-request";

export default {
  name: "YinShuiKaiGuan",
  components: {},
  props: {
    value: Object,
    ispreview: Boolean,
  },

  data() {
    return {
      inputCodeDialog: false,
      userId: "",
      code: "",
      options: {},
      optionsData: {},
      kaiGuanList: [],
      kai_guan_img: require('@/assets/images/shi-fan-dian/kai-guan.png'),
      kai_guan_img_off: require('@/assets/images/shi-fan-dian/kai-guan-off.png'),
    };
  },
  created() {
    this.$bus.$on("clickUserName", (id) => {
      this.userId = id;
      this.kaiGuanList = [];
      this.getKaiGuanData(id);
    });
  },
  mounted() {
  },
  methods: {
    // 获取开关 List
    async getKaiGuanData(id) {
      let data = { id: id };
      let res = await getYinShuiKaiGuanList(data);

      if (res.code == 200) {
        this.kaiGuanList = res.data;
        // console.log("饮水开关信息 : ", res.data);
      }
    },

    // 格式化开关状态
    formatStatus(val) {
      return val == 0 ? "关闭" : "开启";
    },

    // 点击开关
    async handlerClickKaiGuan(index) {
      // 判断 session 中是否存在 user - code
      if (this.judgeCode(this.userId)) {
        // 发送 开关id seitchType code
        let params = {
          id: this.kaiGuanList[index].id,
          switchType: this.kaiGuanList[index].swithType,
          captcha: this.judgeCode(this.userId),
          useShutdownDelay: false,
        };

        // let res = await postKaiGuanStatus(params)

        console.log("点击开关后返回 : ", res);

        // 判断返回状态，如果是 验证码失效了，重新输入验证码
        // this.saveUserId_CdoeToSession(this.userId, 获取Cdoe)
      } else {
        // 向用户发送验证码
        // await this.getCode()

        this.inputCode();
      }

      // 更改 开关图标
      // if (this.kaiGuanList[index].swithType) {
      //   this.kaiGuanList[index].swithType = 0
      // } else {
      //   this.kaiGuanList[index].swithType = 1
      // }
    },

    // 输入 验证码
    inputCode() {
      this.code = "";
      this.inputCodeDialog = true;
    },

    // 点击 弹出框 【确认】
    confirmDialog() {
      this.saveUserId_CdoeToSession(this.userId, this.code);

      this.inputCodeDialog = false;
    },

    // session 中存储验证码
    saveUserId_CdoeToSession(id, code) {
      let user_code_list = JSON.parse(window.sessionStorage["user_code_list"] || null) || [];

      let isHaveUserId = user_code_list.some(item => item.userId == id)

      if (isHaveUserId) {
        for(let i in user_code_list) {
          if (user_code_list[i].userId == id) {
            user_code_list[i].code = code
            break;
          }
        }
      } else {
        user_code_list.push({ userId: id, code: code })
      }

      // session 中存储 userId - code
      window.sessionStorage["user_code_list"] = JSON.stringify(user_code_list);
    },

    // 判断 session 中的验证码
    judgeCode(id) {
      let userCodeList = JSON.parse(window.sessionStorage["user_code_list"] || null) || [];
      for (let i in userCodeList) {
        if (userCodeList[i].userId == id) {
          return userCodeList[i].code;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mu_hu_name {
  margin: 0 10px;
}

.table_box {
  width: 100%;
  height: 70%;
  .table_header {
    width: 100%;
    height: 20px;
    font-size: 18px;
    text-align: left;
    .header_item {
      width: 100px;
      margin-right: 10px;
    }
    .header_item:nth-child(1) {
      width: 120px !important;
    }
    .header_item:nth-child(2) {
      // width: 120px !important;
      padding-left: 30px;
    }
  }

  .table_body_box {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-top: 10px;

    .table_body {
      width: 100%;
      height: 26px;
      font-size: 18px;
      overflow: hidden;

      .body_item {
        width: 100px;
        margin-right: 10px;
        float: left;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .body_item:nth-child(1) {
        width: 90px !important;
      }
      .kai_guan_box {
        width: 50px;
        height: 25px;
        margin-top: 2px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .table_body_box::-webkit-scrollbar {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

.input_dialog {
  text-align: center;

  .input_box {
    width: 100%;
    text-align: center;

    input { border: 1px solid #ccc !important; }
  }
}
</style>
