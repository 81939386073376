<template>
  <div class="text">
    <div class="ti_zhong_jian_ce_box">

      <!-- 按钮组 -->
      <div class="btns_box">
        <div class="weight_btn btn" :class="{ activeColor: activeBtnIndex == '0' }" @click="clickBtn('0')">全年体重成长指数</div>
        <div class="weight_btn btn" :class="{ activeColor: activeBtnIndex == '1' }" @click="clickBtn('1')">季度体重成长指数</div>
        <div class="weight_btn btn" :class="{ activeColor: activeBtnIndex == '2' }" @click="clickBtn('2')">月体重成长指数</div>
        <div class="weight_btn btn" :class="{ activeColor: activeBtnIndex == '3' }" @click="clickBtn('3')">周年体重成长指数</div>
      </div>

      <!-- 折线图 -->
      <div class="zhe_xian_tu_box">
        <!-- <el-carousel class="swiper_ti_zhong_jian_ce" interval="3000" indicator-position="none" ref="swaiper-1">
          <el-carousel-item class="ti_zhong_box" v-for="(item, index) in tiZhongList" :key="index" >
            <lineTest class="ti_zhong_line" :value="lineData"/>
          </el-carousel-item>
        </el-carousel> -->

        <LineChart class="ti_zhong_line" :value="lineData"/>
      </div>

    </div>
  </div>
</template>

<script>
import LineChart from './charts/line-chart.vue'

export default {
  name: "TiZhongJianCe",
  components: { LineChart },
  props: {
    value: Object,
    ispreview: Boolean
  },
  data() {
    return {
      activeBtnIndex: 0,
      yang_zhi_def_img: require('@/assets/images/shi-fan-dian/si-liao-xiao-hao-liang.png'),
      options: {},
      optionsData: {},

      tiZhongList: [1, 2, 3, 4],
      zheXianData: {
        
      },
      lineData: {
        x: [1, 2, 3, 4, 5],
        y: [100, 130, 140, 150, 160]
      }
    };
  },
  
  methods: {
    // 点击 按钮
    clickBtn(val) {
      this.activeBtnIndex = val

      if (val == 0) {
        this.lineData.x = [1, 2, 3, 4, 5]
        this.lineData.y = [100, 130, 140, 150, 160]
      } else if (val == 1) {
        this.lineData.x = [1, 2, 3, 4]
        this.lineData.y = [40, 45, 50, 55]
      } else if (val == 2) {
        this.lineData.x = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        this.lineData.y = [10, 11, 12, 12, 14, 13, 16, 17, 18, 19, 18, 21]
      } else {
        this.lineData.x = [1, 2, 3, 4, 5, 6]
        this.lineData.y = [5, 6, 7, 6, 8, 10]
      }
    },

  }
};
</script>

<style scoped lang="scss">

.activeColor { color: rgb(12, 211, 251) !important; font-weight: 500; }
.text::-webkit-scrollbar {
  display: none;
}

.text {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  scrollbar-width: none;

  .ti_zhong_jian_ce_box {
    width: 100%;
    height: 100%;

    .btns_box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 30px;
      position: relative;
      z-index: 999;
      
      .btn { color: rgb(132, 242, 5); cursor: pointer; padding: 0 !important; }
    }

    .zhe_xian_tu_box {
      width: 100%;
      height: calc(100% - 30px);
      padding: 10px;
      box-sizing: border-box;

      .swiper_ti_zhong_jian_ce { width: 100%; height: 100%; }
    }
  }
}
</style>

<style>
.swiper_ti_zhong_jian_ce > .el-carousel__container { height: 100% !important; }
.ti_zhong_line > .echarts { 
  width: 100% !important; 
  height: 50% !important; 
  position: absolute !important; 
  /* top: 30px; */
  bottom: -30px !important;
  left: 17px !important;
}
</style>